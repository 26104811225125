export default {
  mixRecharge: ["メールアドレスを入力してください"],
  language: "言語",
  common: ["オンラインサービス", "非表示", "確認", "キャンセル"],
  upload: ["アップロード中...", "形式が間違っています", "アップロード成功", "アップロード失敗"],
  vanPull: ["これ以上のデータはありません", "データがありません"],
  login: {
    text: ["言語", "AFX", "ログイン"],
    label: ["ログイン中...", "今すぐログイン"],
    placeholder: ["メールアドレスを入力してください", "パスワードを入力してください"],
    default: ["アカウントをお持ちでないですか?", "今すぐ登録", "パスワードを忘れた?", "パスワードをお忘れですか?"],
    codes: ["アカウントが存在しません", "パスワードが間違っています", "アカウントが凍結されています", "失敗"],
    text2: ['メール', '確認', 'パスワード', '招待', '電話'],
    text1: ['ログイン', 'ウェブサイトへようこそ', 'メールまたは電話', 'パスワード', 'サインイン'],
  },
  register: {
    text: [
      "AFX",
      "確認コードが送信されています...",
      "クリックして取得",
      "登録中...",
      "今すぐ登録",
    ],
    placeholder: [
      "メールアドレスを入力してください",
      "確認コードを入力してください",
      "パスワードを入力してください",
      "パスワードを確認してください",
      "招待コードを入力してください",
      "異なるパスワードが2回入力されています",
      "確認コードを入力してください",
      "無効なメールアドレス"
    ],
    label: ["すでにアカウントをお持ちですか?{a} {line}", "ログインに戻る"],
    codes: ["アカウントが存在します", "コードが空です", "コードが間違っています", "パスワードが一致しません", "誤った推奨", "失敗"]
  },
  resetpwd: ["パスワードを忘れました", "メールアドレスを入力してください", "確認コードを入力してください", "クリックして取得", "パスワードを入力してください", "パスワードをリセット"],
  footer: ["トップページ", "ホール", "注文", "マイページ"],
home: {
  WebMarket: 'WebMarket',
  special_attention: '特別な注意',
  spot_market: 'スポット市場',
  label: ["ようこそ"],
  broadcast: "会員{member}様、{grade}に昇格おめでとうございます",
  menu: ["資金履歴", "チュートリアル", "招待", "カスタマーサービス"],
  noticeTitle: "最新のお知らせ",
  msg: "ミッションは開放されていません",
  video: "ビデオチュートリアルはまだありません",
},
hall: {
  default: [
    "取引フロア", "残高", "購入したい", "売却したい", "売却確認",
    "注文リスト", "売却注文", "商人に売る", "保留中の注文", "購入", "売却"
  ],
  list: [
    '数量', '単価', '未取引', 'USDTを売る', 'USDTの数量を入力してください', '金額を取得', "金額", 'USDTを購入', '消費金額'
  ],
  kbip: ["取り消し", "超過金額", "金額内である必要があります"]
},
msglist: [
  "メッセージリスト"
],
sell: {
  placeholder: ["売却価格を入力してください", "売却する数量を入力してください"],
  label: ["残高(USDT)", "名前", "銀行口座", "現在の価格(USDT)", "売却の最適価格", "売却価格", "売却数量", "最適価格", "すべて", "売却確認"]
},
buy: {
  placeholder: ["購入価格を入力してください", "購入数量を入力してください", "最小金額", "最大金額"],
  label: ["残高(USDT)", "名前", "銀行口座", "現在の価格(USDT)", "最適購入価格", "購入価格", "購入数量", "最適価格", "すべて", "購入確認", "金額"]
},

user: {
  default: [
    "個人センター",
    "ログインアカウント",
    "招待コード",
    "サインアウト",
    "残高",
    "ゴールド",
    "ウォレット",
  ],
  menu: [
    "マイアセット",
    "チームリスト",
    "アカウントのバインド",
    "アカウント詳細",
    "プラットフォーム紹介",
    "サインアウト",
  ],
},
bindAccount: {
  default: ["アカウントをバインド", "送信"],
  fields: ["名前","携帯番号", "アカウント名","銀行名",  "銀行口座",  "種類", "USDTアドレス"],
  placeholder: ["銀行名を入力してください", "アカウント名を入力してください", "銀行口座を入力してください", "電話番号を入力してください", "銀行名を入力してください",
    "USDTの種類を選択してください", "USDTアドレスを入力してください"],
  fields2: ["アカウント", "電話", "カード番号", "銀行名", "種類", "アドレス"],
  placeholder2: ["アカウントを入力してください", "電話を入力してください", "カード番号を入力してください",
    "銀行名を入力してください",
    "USDTの種類を選択してください", "USDTアドレスを入力してください"],
},

wallet: {
  default: [
    "マイアセット",
    "リチャージ",
    "引き出し",
    "リチャージ記録",
    "引き出し記録",
    "リチャージ済み額",
    "販売額",
    "獲得額",
    "アカウント残高",
    "残高",
    "リチャージ額(USDT)",
    "販売額(USDT)",
    "総収益",
    "ビジネスのアップグレード",
    "商人のアップグレードについては、カスタマーサービスに連絡してください",
    "決済済みのビジネスを確認",
    "商人認証",
    "支払いが必要です",
    "支払い証明書",
    "商人の預金",
    "今すぐ認証",
    "情報",
    "支払いが必要です",
    "残高が不足しているため、リチャージできません"
  ],
  label: [
    "引き出し方法",
    "引き出し額",
    "ファンドパスワード",
    "送信",
    "電話番号",
    "メール",
    "IFSC",
    "確認"
  ],
  placeholder: [
    "引き出し方法を選んでください",
    "引き出し額を入力してください",
    "ファンドパスワードを入力してください",
    "引き出し方法を選んでください",
    "受取人の携帯番号を入力してください",
    "受取人のメールを入力してください",
    "受取人のIFSCを入力してください"
  ],
  msg: [
    "ファンドパスワードが設定されていません。まず設定してください。",
    "PIXアカウントがバインドされていません。まずバインドしてください。",
    "引き出しを確定しますか？"
  ],
},
recharge: {
  default: [
    "ウォレットリチャージ",
    "ウォレットの事前チャージ",
    "リチャージ方法",
    "リチャージ",
    "確認"
  ],
  label: [
    "カテゴリ",
    "アドレス",
    "リチャージ額",
    "金額を入力してください",
    "証明書をアップロード",
    "コピー成功",
    "事前チャージ交換"
  ],
  placeholder: [
    "タイプ",
    "アドレス",
  ],
  info: [
    "リチャージ額",
    "注文番号",
    "受取銀行",
    "受取口座",
    "受取人",
    "コピー",
  ],
},
task: {
  tabs: ["すべて", "進行中", "期限切れ", "完了", "支払い待ち", "確認待ち"],
  default: ["注文リスト"],
  msg: ["レビューを提出", "提出しました。レビューをお待ちください", "提出に失敗しました。再提出してください"],
},
userInfo: {
  default: [
    "個人情報",
    "アバター",
    "アカウント",
    "メール",
    "クレジット",
    "詳細",
    "パスワード変更",
    "ファンドパスワード",
    "設定をクリック",
    "アバターを変更",
    "ログインパスワードを変更",
    "ファンドパスワードを変更",
    "送信",
    "キャッシュを空にする",
    "情報を表示",
    "ニックネーム",
    "紹介コード"
  ],
  label: [
    "元のログインパスワード",
    "新しいログインパスワード",
    "パスワードを確認",
    "元のファンドパスワード",
    "新しいファンドパスワード",
    "ファンドパスワードを確認",
    "保存"
  ],
  placeholder: [
    "元のログインパスワードを入力してください",
    "新しいログインパスワードを入力してください",
    "ログインパスワードを確認してください",
    "元のファンドパスワードを入力してください",
    "新しいファンドパスワードを入力してください",
    "ファンドパスワードを確認してください"
  ],
},
fundRecord: {
  default: ["支出記録", "リチャージ記録", "アセットログ", "チャージ", "受け取る", "支部"],
  tabs: ["収入", "支出", "リチャージ"],
},
dialog: [
  "ヒント",
  "確認",
  "送信中...",
  "コピー成功",
  "iOSシステムバージョンはサポートされていません",
  "登録中...",
  "読み込み中...",
  "読み込み中...",
],
serviceCenter: [
  "カスタマーサービス",
  "こんにちは、専任のカスタマーサービスです〜",
  "お手伝いできてうれしいです",
  "セルフサービス",
  "オンラインサービス",
  "リチャージカスタマーサービス",
  "ラインカスタマーサービス"
],
userTaskRecord: [
  "私の注文",
  "販売注文",
  "購入注文",
  "現在の状態",
  "手数料を得る",
  "完了",
],
withdrawlist: [
  "出金記録",
],
teamReport: {
  default: [
    "チームリスト",
  ],
},
common2: ['初心者チュートリアル', "カスタマーサービスに連絡", "利用規約、詳細を見る", "カスタマーサービス1", "カスタマーサービス2"],
common3: ['成功', "プラットフォーム紹介"],
invite: ['プロモーションを共有', '私の招待コード', "招待コードをコピーして、より多くの友達を招待", "コピー"],
common4: ['実名認証', "正常に提出されました", "注意事項",
  "注意事項の内容", "受け取ったプレミアム", "税額", "金融証明書",
  "カード绑定を完了してください", "ID認証を完了してください", "ビジネス認証を完了してください"
],
common5: ["販売済", "分", "前", "QRコードを保存", "開く", "アカウントが凍結されており、現在は取引できません", "アプリ"],

 common6: ["リチャージ注文", "注文番号", "金額", "時間", "状態"],
hall2: ["最小販売額は", "", "1日の販売数を超えています"],
register2: ["メール", "電話", "電話番号を入力してください"],
withdraw2: ["出金", "販売者アカウント", "残高", "支払いが必要", "支払い", "未完了のタスクがあります。現金引き出しは現在できません", "引き出し回数を超えました"],
mytask2: ["商人アカウント", "価格", "金額", "残高", "支払額", "マッチング時間", "注文詳細", "番号は0にできません", "残高不足", "価格は0にできません", "範囲が間違っています"],
taskOrder2: ["まだ未完了の注文があり、キャンセルできません", "残高不足", "確認", "失敗", "注文を完了するまで現金引き出しはできません:"],
busAuth2: ['商人アップグレード', '同意する', "アップグレード確認", "商人アップグレード",
  "銀行カード情報を変更する必要がある場合は、カスタマーサービスに連絡してください",
  "現在のアカウントは商人アカウントにアップグレードする必要があります"
],
recharge2: ['金額が必要', 'スクリーンショットが必要'],
buy3: ["カウントダウン", "時間", "タイプ", '福利注文', 'グループ注文', '制限回数', '制限回数', 'ユーザーIDを指定', 'ユーザーIDを入力'],
hall3: ["日", "時間", "分", "秒", "グループ注文", "アカウント", 'オーバータイム'],
sell3: ['販売価格は最良価格より高くできません'],
busAuth3: ["メンバー", "商人", "クラウン商人"],
recharge3: ["計算"],

 home3: ["マイナー", "推測", "ブラインドボックス", "金融", "近日公開"],
home4: ["クイック取引", "クイックUSDT購入", "C2C取引", "USDT転送", 'オンライン', "24時間取引量(USDT)"],
common7: ["質問があればサービスにお問い合わせください", "サービスに連絡", "ヘルプを求める", "設定", "すべて", "招待", "メンバー数", "月間新規", "登録時間", "部下の人数"],
hall4: ['タイプ', 'メンバー', "アカウント", "取引成功", "完全に成功", "総額", "購入", "販売"],
task3: ["すべて", "進行中", "期限切れ", "完了"],
my: ["ログアウトしてもよろしいですか？"],
bindAccount2: ["情報が正しく入力されていることを確認してください。そうでない場合、通常の取引に影響があります", "今すぐバインド", "カスタマーサービス"],
recharge4: ["資金の安全のため、送金が成功した後、送金成功のスクリーンショットをレビューのために提出してください！"],
resetpwd2: ['メール', '電話', '注意：パスワードは6〜16文字で構成され、大文字と小文字を区別しません'],
home2: ["特別注意", "マネーマーケット"],
login1: ["次へ", "今すぐログイン", "アカウントがないようです。新しいアカウントを作成しましょう:", "同意して続行"],
login2: ["その他のログイン方法", "続行する", "Facebook", "Google", "Apple"],
service2: ["取引", "その他"],
buy2: ["注文タイプ", "購入成功", "USDTは正常に購入されました"],
sell4: ['この販売を確認しますか', "確認", "販売成功", "正常に販売されました", "購入者の支払いを待っています", "完了"],
hall5: ["メンバー"],
busauth: ['本人確認情報', '本名を入力してください', 'ID番号を入力してください', '写真をアップロード', 'ID写真をアップロードしてください', "銀行詳細をアップロードしてください"],
recharge5: ["写真をアップロードしてください", "リチャージ成功", "USDTは正常にアカウントにリチャージされました", "確認"],
note: ["メモ", "ヒント", "電話番号", "電話番号を入力してください", "メッセージを残す", "こちらにメッセージを残してください", "写真をアップロード", "写真をアップロードしてください", "正常に送信されました", "できるだけ早くご連絡いたします", "閉じる"],
notelist: ["メモリスト"],
search: ['検索', 'データが見つかりません', "検索キーワードを入力してください"],
searchs: {
    orders: "注文", recharge: "リチャージ", withdraw: "出金"
},
team2: ["招待詳細", "友達を招待"],
task4: ["私のタスク"],
lang3: ["英語", "韓国語", "台湾語"],
login3: ["アカウントまたはパスワードが間違っています。再度入力してください"],
shiming: ['税務監査'],
errauth: ['アカウント違反', "規則に違反しており、支払う必要があります:", "あなたのアカウントは違反しています"],
  '账户名':"口座名",
  '银行卡号':'銀行カード番号',
  '银行名称':'銀行名'
}
