export default {
  mixRecharge: ["이메일 주소를 입력해주세요"],
  language: "언어",
  common: ["온라인 서비스", "마우스를 올리면", "확인", "취소"],
  upload: ["업로드 중...", "잘못된 형식", "업로드 성공", "업로드 실패"],
  vanPull: ["더 이상 데이터가 없습니다", "데이터가 없습니다"],
  login: {
    text: ["언어", "AFX", "로그인"],
    label: ["로그인 중...", "지금 로그인"],
    placeholder: ["이메일을 입력해주세요", "비밀번호를 입력해주세요"],
    default: ["계정이 없으신가요?", "지금 가입하세요", "비밀번호를 잊으셨나요?", "비밀번호를 잊으셨나요?"],
    codes: ["계정이 존재하지 않습니다", "잘못된 비밀번호", "계정이 동결되었습니다", "실패"],
    text2: ['메일', '인증', '비밀번호', '초대', '전화'],
    text1: ['로그인', '웹에 오신 것을 환영합니다', "이메일 또는 전화", '비밀번호', '로그인'],
  },
  register: {
    text: [
      "AFX",
      "인증 코드가 전송 중입니다...",
      "클릭하여 받기",
      "등록 중...",
      "지금 가입하세요",
    ],
    placeholder: [
      "이메일을 입력해주세요",
      "인증 코드를 입력해주세요",
      "비밀번호를 입력해주세요",
      "비밀번호를 확인해주세요",
      "초대 코드를 입력해주세요",
      "두 번의 비밀번호가 일치하지 않습니다",
      "인증 코드를 입력해주세요",
      "잘못된 이메일"
    ],
    label: ["이미 계정이 있으신가요?{a} {line}", "로그인으로 돌아가기"],
    codes: ["계정이 존재합니다", "빈 코드", "잘못된 코드", "비밀번호가 일치하지 않습니다", "잘못된 추천", "실패"]
  },
  resetpwd: ["비밀번호를 잊으셨나요", "이메일을 입력해주세요", "인증 코드를 입력해주세요", "클릭하여 받기", "비밀번호를 입력해주세요", "비밀번호 재설정"],
  footer: ["홈", "홀", "주문", "내 정보"],
  home: {
    WebMarket: '웹마켓',
    special_attention: '특별 주의',
    spot_market: '스폿 마켓',
    label: ["환영합니다"],
    broadcast: "축하합니다, 회원{member}님이 {grade} 등급이 되셨습니다.",
    menu: ["자금 일지", "튜토리얼", "초대", "고객 서비스"],
    noticeTitle: "최신 공지사항",
    msg: "미션이 열리지 않았습니다",
    video: "현재 비디오 튜토리얼이 없습니다",
  },
  hall: {
    default: [
      "거래소", "잔액", "구매 희망", "판매 희망", "판매 확인",
      "주문 목록", "판매 주문", "상인에게 판매", "보류 중 주문", "구매", "판매"
    ],
    list: [
      '수량', '단가', '거래되지 않음', 'USDT 판매', 'USDT 금액을 입력하세요', '금액 얻기', "금액", 'USDT 구매', '소비한 금액'
    ],
    kbip: ["철회", "초과 금액", "금액 범위 내여야 합니다"]
  },
  msglist: [
    "메시지 목록"
  ],
  sell: {
    placeholder: ["판매 가격을 입력하세요", "판매할 수량을 입력하세요"],
    label: ["잔액(USDT)", "이름", "은행 계좌", "현재 가격(USDT)", "판매 최적 가격",
      "판매 가격", "판매된 수량", "최고 가격", "전체", "판매 확인"]
  },
  buy: {
    placeholder: ["구매 가격을 입력하세요", "구매 수량을 입력하세요", "최소 금액", "최대 금액"],
    label: ["잔액(USDT)", "이름", "은행 계좌", "현재 가격(USDT)", "최고 구매 가격", "구매 가격", "구매 수량", "최고 가격", "전체", "구매 확인", "금액"]
  },
  user: {
    default: [
      "개인 센터", "로그인 계정", "초대 코드", "로그아웃", "잔액", "금", "지갑",
    ],
    menu: [
      "내 자산", "팀 목록", "계정 바인딩", "계정 상세", "플랫폼 소개", "로그아웃",
    ],
  },
  bindAccount: {
    default: ["계정 연결", "제출"],
    fields: ["이름","휴대폰 번호", "계좌 이름", "은행 이름", "은행 계좌",  "유형", "USDT 주소"],
    placeholder: ["은행 이름을 입력하세요", "계좌 이름을 입력하세요", "은행 계좌를 입력하세요", "휴대폰 번호를 입력하세요", "은행 이름을 입력하세요",
      "USDT 유형을 선택하세요", "USDT 주소를 입력하세요"],
    fields2: ["계좌", "전화", "카드 번호", "은행 이름", "유형", "주소"],
    placeholder2: ["계좌를 입력하세요", "전화번호를 입력하세요", "카드 번호를 입력하세요",
      "은행 이름을 입력하세요", "USDT 유형을 선택하세요", "USDT 주소를 입력하세요"],
  },
  wallet: {
    default: [
      "내 자산", "충전", "출금", "충전 기록", "출금 기록", "충전 금액", "판매 금액", "수익", "계좌 잔액",
      "잔액", "충전 금액(USDT)", "판매 금액(USDT)", "총 수익",
      "비즈니스 업그레이드", "상인 업그레이드를 원하시면 고객 서비스에 문의하세요",
      "결제된 비즈니스 확인", "상인 인증", "결제가 필요합니다", "결제 증빙", "상인 예치금", "지금 인증", "정보", "결제가 필요합니다", "잔액이 부족하여 충전할 수 없습니다"
    ],
    label: [
      "출금 방법", "출금 금액", "자금 비밀번호", "제출", "전화번호", "메일", "IFSC", "확인",
    ],
  },

placeholder: [
  "출금 방법을 선택하세요",
  "출금 금액을 입력하세요",
  "자금 비밀번호를 입력하세요",
  "출금 방법을 선택하세요",
  "수취인의 휴대폰 번호를 입력하세요",
  "수취인 이메일을 입력하세요",
  "수취인 IFSC를 입력하세요",
],
msg: [
  "자금 비밀번호를 설정하지 않으셨습니다. 먼저 설정해주세요",
  "PIX 계좌를 바인딩하지 않으셨습니다. 먼저 바인딩해주세요",
  "정말 출금하시겠습니까?",
],
recharge: {
  default: [
    "지갑 충전",
    "지갑 선충전",
    "충전 방법",
    "충전",
    "확인",
  ],
  label: [
    "카테고리",
    "주소",
    "충전 금액",
    "금액 입력",
    "증명서 업로드",
    "복사 성공",
    "선충전 거래소"
  ],
  placeholder: [
    "유형",
    "주소",
  ],
  info: [
    "충전 금액",
    "주문 번호",
    "수취 은행",
    "수취 계좌",
    "수취인",
    "복사",
  ],
},
task: {
  tabs: ["전체", "진행 중", "만료된", "완료됨", "대기 중 결제", "대기 중 확인"],
  default: ["주문 목록"],
  msg: ["리뷰 제출", "제출됨, 리뷰를 기다려 주세요", "제출 실패, 다시 제출해 주세요"],
},
userInfo: {
  default: [
    "개인 정보",
    "아바타",
    "계정",
    "메일",
    "신용",
    "상세",
    "비밀번호 변경",
    "자금 비밀번호",
    "설정 클릭",
    "아바타 수정",
    "로그인 비밀번호 수정",
    "자금 비밀번호 수정",
    "제출",
    "캐시 비우기",
    "정보 보기",
    "닉네임",
    "초대 코드"
  ],
  label: [
    "기존 로그인 비밀번호",
    "새 로그인 비밀번호",
    "비밀번호 확인",
    "기존 자금 비밀번호",
    "새 자금 비밀번호",
    "비밀번호 확인",
    "저장"
  ],
  placeholder: [
    "기존 로그인 비밀번호를 입력하세요",
    "새 로그인 비밀번호를 입력하세요",
    "로그인 비밀번호를 확인하세요",
    "기존 자금 비밀번호를 입력하세요",
    "새 자금 비밀번호를 입력하세요",
    "자금 비밀번호를 확인하세요",
  ],
},
fundRecord: {
  default: ["지출 기록", "충전 기록", "자산 로그", "충전", "수신", "지점"],
  tabs: ["수입", "지출", "충전"],
},

 dialog: [
  "알림",
  "확인",
  "제출 중...",
  "복사 성공",
  "IOS 시스템 버전은 지원되지 않습니다",
  "등록 중...",
  "로딩 중...",
  "로딩 중...",
],
serviceCenter: [
  "고객 서비스",
  "안녕하세요, 저는 전담 고객 서비스입니다~",
  "귀하에게 서비스를 제공하게 되어 기쁩니다",
  "자기 서비스",
  "온라인 서비스",
  "충전 고객 서비스",
  "라인 고객 서비스",
],
userTaskRecord: [
  "내 주문",
  "판매 주문",
  "구매 주문",
  "현재 상태",
  "수수료 받기",
  "완료됨",
],
withdrawlist: [
  "출금 기록",
],
teamReport: {
  default: [
    "팀 목록",
  ],
},
common2: ['초보자 튜토리얼', "고객 서비스 연락", "서비스 약관, 자세히 보기", "고객 서비스 1", "고객 서비스 2"],
common3: ['성공', "플랫폼 소개"],
invite: ['프로모션 공유', '내 초대 코드', "초대 코드를 복사하여 더 많은 친구들을 초대하세요", "복사"],
common4: ['실명 인증', "제출 성공", "주의 사항",
  "주의 사항 내용", "받은 프리미엄", "세금 금액", "금융 증명서",
  "카드 바인딩을 완료해주세요", "ID 인증을 완료해주세요", "비즈니스 인증을 완료해주세요"],
common5: ["판매됨", "분", "전", "QR 코드 저장", "열기", "계정이 동결되어 현재 거래할 수 없습니다", "APP"],
common6: ["충전 주문", "주문 번호", "금액", "시간", "상태"],
hall2: ["최소 판매 금액은", "", "일일 판매 횟수 초과"],
register2: ["메일", "전화", "전화번호를 입력하세요"],
withdraw2: ["출금", "판매자 계좌", "잔액", "지불 필요", "지불", "아직 완료되지 않은 작업이 있습니다. 현재 현금 출금은 허용되지 않습니다", "출금 횟수 초과"],
mytask2: ["상인 계좌", "가격", "수량", "잔액", "지불 금액", "매칭 시간", "주문 상세", "번호는 0일 수 없습니다", "잔액이 부족합니다", "가격은 0일 수 없습니다", "잘못된 범위"],
taskOrder2: ["열린 주문이 있어 취소할 수 없습니다", "잔액이 부족합니다", "확인", "실패", "주문을 완료해야 현금을 출금할 수 있습니다:"],
busAuth2: ['상인 업그레이드', '동의함', "업그레이드 확인", "상인 업그레이드",
  "은행 카드 정보를 수정해야 하는 경우 고객 서비스에 문의하세요",
  "현재 계정은 상인 계정으로 업그레이드해야 합니다"],
recharge2: ['필요 금액', '스크린샷 필요'],
buy3: ["카운트다운", "시간", "유형", '복지 주문', '그룹 주문', '카운트 제한', '카운트 제한', '지정된 사용자 ID', '사용자 ID 입력'],
hall3: ["일", "시간", "분", "초", "그룹 주문", "계정", '시간 초과'],
sell3: ['판매 가격은 최적 가격보다 클 수 없습니다'],
busAuth3: ["회원", "상인", "크라운 상인"],
recharge3: ["계산"],

home3: ["채굴기", "추측", "블라인드 박스", "재정", "곧 출시 예정"],
home4: ["빠른 거래", "빠른 USDT 구매", "C2C 거래", "USDT 전송", '온라인', "24시간 금액(USDT)"],
common7: ["질문이 있으면 고객 서비스에 문의하세요", "고객 서비스에 문의", "도움 요청", "설정", "모두", "초대", "회원 수", "이번 달 신규", "등록 시간", "하위 인원 수"],
hall4: ['유형', '회원', "귀하의 계정", "거래 성공", "완료 성공", "총 금액", "구매", "판매"],
task3: ["전체", "진행 중", "만료됨", "완료됨"],
my: ["로그아웃 하시겠습니까?"],
bindAccount2: ["정보가 올바르게 입력되었는지 확인하십시오. 그렇지 않으면 정상적인 거래에 영향을 미칠 수 있습니다.",
  "지금 바인딩", "고객 서비스"],
recharge4: ["귀하의 자금을 안전하게 보호하기 위해, 전송이 완료된 후 성공적인 전송 스크린샷을 제출하여 검토를 받으세요!"],
resetpwd2: ['메일', '전화', '참고: 비밀번호는 6 ~ 16자의 문자를 사용하며 대소문자를 구분하지 않습니다'],
home2: ["특별 주의", "금융 시장"],
login1: ["다음", "지금 로그인", "계정이 없는 것 같습니다. 새 계정을 생성해 보세요:", "동의하고 계속"],
login2: ["더 많은 로그인 방법", "계속하기", "페이스북", "구글", "애플"],
service2: ["거래", "기타"],
buy2: ["주문 유형", "구매 성공", "USDT가 성공적으로 구매되었습니다"],
sell4: ['이 판매를 확인하시겠습니까?', "확인", "판매 성공", "성공적으로 판매되었습니다", "구매자가 결제할 때까지 기다립니다", "완료"],
hall5: ["회원"],
busauth: ['신원 정보', '실명을 입력하세요', '주민등록번호를 입력하세요', '사진 업로드', '신분증 사진을 업로드해주세요', '은행 정보 업로드'],
recharge5: ["사진을 업로드해주세요", "충전 성공", "USDT가 계좌에 성공적으로 충전되었습니다", "확인"],
note: ["메모", "팁", "전화번호", "전화번호를 입력하세요", "메시지 남기기", "여기에 메시지를 남겨주세요", "사진 업로드", "사진을 업로드해주세요", "제출 성공", "최대한 빨리 연락드리겠습니다", "닫기"],
notelist: [
  "메모 목록"
],
search: ['검색', '데이터 없음', "검색어를 입력하여 찾으세요"],
searchs: {
  orders: "주문", recharge: "충전", withdraw: "출금"
},
team2: ["초대 세부사항", "친구 초대"],
task4: ["내 작업"],
lang3: ["영어", "한국어", "대만어"],
login3: ["계정 또는 비밀번호 오류, 다시 입력해주세요"],
shiming: ['세금 감사'],
errauth: ['계정 위반', "규정을 위반하셔서 지불해야 합니다:", "귀하의 계정이 규정을 위반했습니다"],
  '账户名':"성명",
  '银行卡号':'은행카드번호',
  '银行名称':'은행명',
  '保证金':'보증금',
  '保证金缴纳凭证':'보증금 납부 증명서'
};
